import React from 'react'
import { Link } from 'gatsby'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
  FacebookIcon,
  EmailIcon,
  LinkedinIcon,
} from 'react-share'

import { buildImageObj, format } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import AuthorList from './author-list'
import AdItem from './adItem'
import * as styles from './blog-post.module.css'
import { responsiveTitle3 } from './typography.module.css'

function BlogPost(props) {
  const { _rawBody, authors, slug, category, title, mainImage, publishedAt, related } = props
  const hasAuthor = authors && Array.isArray(authors) && authors.length > 0
  return (
    <article className={styles.root}>
      {mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1200)
              .height(Math.floor((9 / 16) * 1200))
              .fit('crop')
              .auto('format')
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )}
      <Container style={{ direction: 'ltr', marginTop: '2rem' }}>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
            <div>
              <TwitterShareButton url={`https://qasweb.org/${category.name}/${slug.current}`}>
                <TwitterIcon round size={32} />
              </TwitterShareButton>
              <WhatsappShareButton url={`https://qasweb.org/${category.name}/${slug.current}`}>
                <WhatsappIcon round size={32} />
              </WhatsappShareButton>
              <FacebookShareButton url={`https://qasweb.org/${category.name}/${slug.current}`}>
                <FacebookIcon round size={32} />
              </FacebookShareButton>
              <TelegramShareButton url={`https://qasweb.org/${category.name}/${slug.current}`}>
                <TelegramIcon round size={32} />
              </TelegramShareButton>
              <EmailShareButton url={`https://qasweb.org/${category.name}/${slug.current}`}>
                <EmailIcon round size={32} />
              </EmailShareButton>
              <LinkedinShareButton url={`https://qasweb.org/${category.name}/${slug.current}`}>
                <LinkedinIcon round size={32} />
              </LinkedinShareButton>
            </div>
            <section className={styles.article__body}>
              {_rawBody && <PortableText blocks={_rawBody} />}
            </section>
            <AdItem />
          </div>
          <aside className={styles.metaContent}>
            {publishedAt && (
              <>
                <div className={styles.publishedAtHeadline}>تاريخ النشر</div>
                <div className={styles.publishedAt}>
                  <time dateTime={new Date(publishedAt).toLocaleDateString('en-US')}>
                    {new Date(publishedAt).toLocaleDateString('ar-SA', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </time>
                </div>
              </>
            )}
            {hasAuthor && <AuthorList items={authors} title="Authors" />}
            {category && (
              <div className={styles.categories}>
                <h3 className={responsiveTitle3}>التصنيفات</h3>
                <ul>
                  <li key={category._id}>
                    {/* todo: remove hardcoded anshth-w-faalyat */}
                    <Link
                      to={`/${
                        category.name === 'activities' ? 'anshth-w-faalyat' : category.name
                      }`}
                    >
                      {category.name_ar}
                    </Link>
                  </li>
                </ul>
              </div>
            )}
            {related && (
              <div className={styles.related}>
                <h3 className={responsiveTitle3}>إقرأ أيضاً</h3>
                <ul>
                  {related.map(({ title, slug }) => (
                    <li key={slug.current}>
                      <Link to={`../${slug.current}`}>{title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </aside>
        </div>
      </Container>
    </article>
  )
}

export default BlogPost
