// extracted by mini-css-extract-plugin
export var article__body = "blog-post-module--article__body--c54ee";
export var base = "blog-post-module--base--783f8";
export var blockQuote = "blog-post-module--blockQuote--1c0e5";
export var categories = "blog-post-module--categories--007e3";
export var grid = "blog-post-module--grid--40e49";
export var large = "blog-post-module--large--dabc2";
export var mainContent = "blog-post-module--mainContent--78b3b";
export var mainImage = "blog-post-module--mainImage--20c31";
export var metaContent = "blog-post-module--metaContent--9ca04";
export var micro = "blog-post-module--micro--08a9f";
export var microCustom = "blog-post-module--microCustom--52ecf";
export var paragraph = "blog-post-module--paragraph--5e3ed";
export var publishedAt = "blog-post-module--publishedAt--fe080 blog-post-module--small--cd3bc";
export var publishedAtHeadline = "blog-post-module--publishedAtHeadline--e38c0 blog-post-module--small--cd3bc";
export var related = "blog-post-module--related--0dc10";
export var responsiveTitle1 = "blog-post-module--responsiveTitle1--f2080";
export var responsiveTitle2 = "blog-post-module--responsiveTitle2--f393a";
export var responsiveTitle3 = "blog-post-module--responsiveTitle3--46db0";
export var responsiveTitle4 = "blog-post-module--responsiveTitle4--a42f0";
export var root = "blog-post-module--root--c31ef";
export var small = "blog-post-module--small--cd3bc";
export var smallCustom = "blog-post-module--smallCustom--cf4e9";
export var title = "blog-post-module--title--f5d0a blog-post-module--responsiveTitle1--f2080";
export var title1 = "blog-post-module--title1--8c8e6";
export var title2 = "blog-post-module--title2--83102";
export var title3 = "blog-post-module--title3--1d23a";