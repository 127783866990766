import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import BlogPost from '../components/blog-post'
import { imageUrlFor } from '../lib/image-url'
import { buildImageObj } from '../lib/helpers'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { toPlainText } from '../lib/helpers'
import Breadcrumb from '../components/layout/breadcrumb'

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      category {
        _id
        name
        name_ar
      }
      tags {
        name
        name_ar
      }
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 6 })
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
    }
  }
`

const BlogPostTemplate = props => {
  const { data, errors } = props
  const post = data && data.post
  const { tags, category, slug } = post || {}
  const imageUrl = post?.mainImage?.asset
    ? imageUrlFor(buildImageObj(post.mainImage))
        .width(1200)
        .height(600)
        .fit('scale')
        .format('jpg')
        .quality(65)
        .auto('format')
        .url()
    : 'https://qasweb.org/qasLogo_1_777.jpg'
  let navItemStack = []
  if (category && category.name)
    navItemStack.push({
      name: category.name_ar,
      slug: category.name === 'activities' ? 'anshth-w-faalyat' : category.name,
    })
  if (tags && Array.isArray(tags) && tags.length)
    navItemStack.push({ name: tags[0].name_ar, slug: `tags/${tags[0].name}` })
  const ogDescription =
    toPlainText(post._rawExcerpt).length > 10
      ? toPlainText(post._rawExcerpt)
      : toPlainText(post._rawBody).substring(0, 100)
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {post && (
        <SEO
          path={`/${category.name}/${slug?.current}`}
          title={post.title?.substring(0, 59) || 'Untitled'}
          fullTitle={post.title || 'Untitled'}
          description={ogDescription}
          image={imageUrl}
          type="article"
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      <span itemprop="thumbnail" itemscope itemtype="image/jpeg">
        <link itemprop="thumbnailUrl" href={imageUrl} />
        <link itemprop="url" href={imageUrl} />
        <meta itemprop="width" content="1200" />
        <meta itemprop="height" content="600" />
      </span>
      <Container style={{ minHeight: 'unset' }}>
        <Breadcrumb activeLink={true} currentNavItems={navItemStack} />
      </Container>
      {post && <BlogPost {...post} />}
    </Layout>
  )
}

export default BlogPostTemplate

export const Head = ({ data: { post }, location }) => {
  const { title } = post
  const ogDescription =
    toPlainText(post?._rawExcerpt).length > 10
      ? toPlainText(post?._rawExcerpt)
      : toPlainText(post?._rawBody)
          .substring(0, 100)
          .replace(/\n/g, '')
  const imageUrl = post?.mainImage?.asset
    ? imageUrlFor(buildImageObj(post.mainImage))
        .width(1200)
        .height(630)
        .fit('scale')
        .format('jpg')
        .quality(65)
        .auto('format')
        .url()
    : ''
  return (
    <SEO
      title={title}
      description={ogDescription}
      image={imageUrl}
      pathname={location.pathname}
    >
      <meta property="og:type" content="article" />
      <meta
        property="article:published_time"
        content={new Date(post.publishedAt).toISOString()}
      />
    </SEO>
  )
}
