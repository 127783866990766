// extracted by mini-css-extract-plugin
export var avatar = "author-list-module--avatar--3e2cf";
export var base = "author-list-module--base--f2198";
export var blockQuote = "author-list-module--blockQuote--87062";
export var headline = "author-list-module--headline--72f7d author-list-module--base--f2198";
export var large = "author-list-module--large--fddff";
export var list = "author-list-module--list--1202d";
export var listItem = "author-list-module--listItem--8012a";
export var micro = "author-list-module--micro--bde47";
export var microCustom = "author-list-module--microCustom--84554";
export var paragraph = "author-list-module--paragraph--52b60";
export var responsiveTitle1 = "author-list-module--responsiveTitle1--71094";
export var responsiveTitle2 = "author-list-module--responsiveTitle2--5f473";
export var responsiveTitle3 = "author-list-module--responsiveTitle3--2ee44";
export var responsiveTitle4 = "author-list-module--responsiveTitle4--f89fe";
export var root = "author-list-module--root--78f57";
export var small = "author-list-module--small--215a2";
export var smallCustom = "author-list-module--smallCustom--cd072";
export var title1 = "author-list-module--title1--7f0c8";
export var title2 = "author-list-module--title2--bce86";
export var title3 = "author-list-module--title3--2861b";